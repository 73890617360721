import { Box, Tab, Table, TableBody, TableCell, TableContainer, TableRow, Tabs } from '@material-ui/core';
import { BarChart, List } from '@material-ui/icons';
import { TableHead } from '@mui/material';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ILegends, IMeasurementSiteConfig, IOperator } from '../../../models/config';
import { IMeasurementSiteDetails } from '../../../models/misc';
import { formatNumber, momentFormatDate } from '../../../service/chartDataHandler';
import { useConfigRequest } from '../../../service/globalService';
import EDetailChart from '../../elements/detail-chart/EDetailChart';
import EDetailTable from '../../elements/detail-table/EDetailTable';
import ErrorBoundary from '../../elements/error-boundary/EErrorBoundary';
import EHelpIcon from '../../elements/help-icon/EHelpIcon';
import MWaterEvents from '../water-events/MWaterEvents';
import './MDetailMeasurementSites.scss';
import MDetailMeasurementSitesMetaData from './MDetailMeasurementSitesMetaData';

interface IProps {
    msConfig: IMeasurementSiteConfig | null;
    measurementSitesData: null | IMeasurementSiteDetails;
    msNumber: number | null;
    legends?: ILegends;
    operator: IOperator;
}

const MDetailMeasurementSites = (props: IProps) => {
    const [tabValue, setTabValue] = useState(0);
    const [dataViewType, setDataViewType] = useState('chart');
    const history = useHistory();
    const { riverAreaSlug, msSlug } = useParams<{ riverAreaSlug: string; msSlug?: string }>();
    const locationHash: string = useLocation().hash;

    const [type, setType] = useState<string>(null);

    const { data: config } = useConfigRequest();

    const params = useParams();
    //@ts-expect-error param types
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const msSlugTmp = params.msSlug;

    useEffect(() => {
        if (config) {
            const measurementsites: IMeasurementSiteConfig[] = Object.values(config.measurementsite);
            setType(
                measurementsites.filter((measurementsite: IMeasurementSiteConfig) => {
                    return measurementsite.slug === msSlugTmp;
                })[0].type
            );
        }
    }, [config, msSlugTmp]);

    /**
     * Check if all conditions for a specific tab are met.
     */
    const hasTab = useCallback(
        (name: TTabName): boolean => {
            switch (name) {
                case 'W':
                    return !!props.measurementSitesData?.W?.measurements?.length;
                case 'Q':
                    return !!props.measurementSitesData?.Q?.measurements?.length;
                case 'details':
                    return true;
                default:
                    return false;
            }
        },
        [props.measurementSitesData]
    );

    // Reset view to first tab and chart mode if measurement site changes.
    useEffect(() => {
        setDataViewType('chart');

        // initialize tabValue depending on given hash in route
        if (locationHash.length) {
            let newTabValue = 0;
            if (hasTab('W') && hasTab('Q')) {
                switch (locationHash) {
                    case '#abfluesse':
                        newTabValue = 1;
                        break;
                    case '#pegelkennwerte':
                        newTabValue = 2;
                        break;
                }
            } else if (hasTab('W') && !hasTab('Q')) {
                switch (locationHash) {
                    case '#pegelkennwerte':
                        newTabValue = 1;
                        break;
                }
            } else if (!hasTab('W') && hasTab('Q')) {
                switch (locationHash) {
                    case '#pegelkennwerte':
                        newTabValue = 1;
                        break;
                }
            }

            // if (newTabValue !== tabValue) {
            setTabValue(newTabValue);
            // }
        }
    }, [props.msNumber, locationHash, hasTab]);

    type TTabName = 'W' | 'Q' | 'details';

    /**
     * Check if specific tab is active.
     */
    function isTabActive(name: TTabName): boolean {
        const [hasW, hasQ] = [hasTab('W'), hasTab('Q')];
        switch (name) {
            case 'W':
                return hasW && tabValue === 0;
            case 'Q':
                return hasQ && tabValue === (hasW ? 1 : 0);
            case 'details':
                return tabValue === (hasW && hasQ ? 2 : hasW || hasQ ? 1 : 0);
            default:
                return false;
        }
    }

    const legendWKeysSorted = Object.keys(props.legends.W).sort((a, b) => {
        return props.legends.W[a].sorting - props.legends.W[b].sorting;
    });

    const legendQKeysSorted = Object.keys(props.legends.Q).sort((a, b) => {
        return props.legends.Q[a].sorting - props.legends.Q[b].sorting;
    });

    const TabPanel = (tabPanelProps: { className: string; value: number; index: number; children: ReactNode }) => {
        const { children, value, index, ...other } = tabPanelProps;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box className="m-detail-measurementsites__tab-box" p={3}>
                        {children}
                    </Box>
                )}
            </div>
        );
    };

    return (
        type !== null && (
            <div className="m-detail-measurementsites">
                <Box className="m-detail-measurementsites__navigation">
                    <div className="m-detail-measurementsites__action-buttons">
                        {dataViewType === 'chart' && !isTabActive('details') && (
                            <div className="m-detail-measurementsites__button-wrapper">
                                <button
                                    className="m-detail-measurementsites__table-button"
                                    aria-label="list"
                                    onClick={() => setDataViewType('table')}
                                >
                                    <List className={'m-detail-measurementsites__table-button__list-icon'} />
                                </button>
                            </div>
                        )}
                        {dataViewType === 'table' && !isTabActive('details') && (
                            <div className="m-detail-measurementsites__button-wrapper">
                                <button
                                    className="m-detail-measurementsites__chart-button"
                                    aria-label="chart"
                                    onClick={() => setDataViewType('chart')}
                                >
                                    <BarChart className={'m-detail-measurementsites__chart-button__chart-icon'} />
                                </button>
                            </div>
                        )}
                        <EHelpIcon
                            hash={'pegel-ganglinie-steuerung'}
                            label="Erläuterungen zu der Pegel Ganglinien Steuerung"
                        />
                    </div>

                    <Tabs
                        value={tabValue}
                        onChange={(_, value: number) => setTabValue(value)}
                        aria-label="m-detail-measurementsites__tabs"
                    >
                        {hasTab('W') && (
                            <Tab
                                tabIndex={1}
                                label="Wasserstände"
                                className="m-detail-measurementsites__tab-button"
                                onClick={() => {
                                    if (type === 'municipal') {
                                        history.push('/' + riverAreaSlug + '/' + msSlug + '#wasserstaende');
                                    } else {
                                        history.push('/flussgebiet/' + riverAreaSlug + '/' + msSlug + '#wasserstaende');
                                    }
                                }}
                            />
                        )}

                        {hasTab('Q') && (
                            <Tab
                                tabIndex={1}
                                label="Abflüsse"
                                className="m-detail-measurementsites__tab-button"
                                onClick={() => {
                                    if (type === 'municipal') {
                                        history.push('/' + riverAreaSlug + '/' + msSlug + '#abfluesse');
                                    } else {
                                        history.push('/flussgebiet/' + riverAreaSlug + '/' + msSlug + '#abfluesse');
                                    }
                                }}
                            />
                        )}
                        <Tab
                            tabIndex={1}
                            label="Pegelkennwerte"
                            className="m-detail-measurementsites__tab-button"
                            onClick={() => {
                                if (type === 'municipal') {
                                    history.push('/' + riverAreaSlug + '/' + msSlug + '#pegelkennwerte');
                                } else {
                                    history.push('/flussgebiet/' + riverAreaSlug + '/' + msSlug + '#pegelkennwerte');
                                }
                            }}
                        />
                    </Tabs>
                </Box>

                {hasTab('W') && (
                    <TabPanel className="m-detail-measurementsites__tab-panel" value={tabValue} index={0}>
                        <div
                            className={'m-detail-measurementsites__chart' + (dataViewType !== 'chart' ? ' hidden' : '')}
                        >
                            <ErrorBoundary errorMsg={'Wasserstand konnte nicht geladen werden'}>
                                <EDetailChart
                                    msData={props.measurementSitesData.W}
                                    msConfig={props.msConfig}
                                    waterlevel={true}
                                    thresholds={
                                        props.legends?.thresholds && props.legends.thresholds[props.msNumber]?.W
                                    }
                                    legends={props.legends?.W}
                                    label={props.msConfig.name}
                                    downloadUrl={props.measurementSitesData.downloadUrl}
                                >
                                    <ErrorBoundary errorMsg={'Metadaten konnten nicht geladen werden'}>
                                        <MDetailMeasurementSitesMetaData
                                            msData={props.measurementSitesData.W}
                                            operator={props.operator}
                                            legendInfo={{
                                                legendText: props.msConfig?.legendText,
                                                legendURL: props.msConfig?.legendURL,
                                                forecastTime: props.measurementSitesData.W.predictions?.time,
                                            }}
                                            isSeaSite={props.msConfig?.isSeaSite}
                                            isWaterLevel={true}
                                        />
                                    </ErrorBoundary>
                                </EDetailChart>
                            </ErrorBoundary>
                        </div>

                        <div
                            className={'m-detail-measurementsites__table' + (dataViewType !== 'table' ? ' hidden' : '')}
                        >
                            <ErrorBoundary errorMsg={'Tabelle konnte nicht geladen werden'}>
                                <EDetailTable
                                    data={props.measurementSitesData.W.measurements}
                                    predictionData={props.measurementSitesData.W.predictions?.p50}
                                    waterLevel={true}
                                    isSeaSite={props.msConfig?.isSeaSite}
                                >
                                    <ErrorBoundary errorMsg={'Metadaten konnten nicht geladen werden'}>
                                        <MDetailMeasurementSitesMetaData
                                            msData={props.measurementSitesData.W}
                                            operator={props.operator}
                                            legendInfo={{
                                                legendText: props.msConfig?.legendText,
                                                legendURL: props.msConfig?.legendURL,
                                                forecastTime: props.measurementSitesData.W.predictions?.time,
                                            }}
                                            isSeaSite={props.msConfig?.isSeaSite}
                                            isWaterLevel={true}
                                        />
                                    </ErrorBoundary>
                                </EDetailTable>
                            </ErrorBoundary>
                        </div>

                        {type !== 'municipal' && (
                            <MWaterEvents
                                extremeEvents={props.measurementSitesData?.extremeevents?.W}
                                legends={props.legends?.W}
                                threshold={props.legends?.thresholds[props.msNumber]?.W}
                            />
                        )}
                    </TabPanel>
                )}

                {hasTab('Q') && (
                    <TabPanel
                        className="m-detail-measurementsites__tab-panel"
                        value={tabValue}
                        index={hasTab('W') ? 1 : 0}
                    >
                        <div
                            className={'m-detail-measurementsites__chart' + (dataViewType !== 'chart' ? ' hidden' : '')}
                        >
                            <ErrorBoundary errorMsg={'Wasserstand konnte nicht geladen werden'}>
                                <EDetailChart
                                    msData={props.measurementSitesData?.Q}
                                    msConfig={props.msConfig}
                                    waterlevel={false}
                                    thresholds={props.legends?.thresholds[props.msNumber]?.Q}
                                    legends={props.legends?.Q}
                                    label={props.msConfig.name}
                                >
                                    <ErrorBoundary errorMsg={'Metadaten konnten nicht geladen werden'}>
                                        <MDetailMeasurementSitesMetaData
                                            msData={props.measurementSitesData?.Q}
                                            operator={props.operator}
                                            legendInfo={{
                                                legendText: props.msConfig?.legendText,
                                                legendURL: props.msConfig?.legendURL,
                                                forecastTime: props.measurementSitesData.Q.predictions?.time,
                                            }}
                                            isWaterLevel={false}
                                        />
                                    </ErrorBoundary>
                                </EDetailChart>
                            </ErrorBoundary>
                        </div>
                        <div
                            className={'m-detail-measurementsites__table' + (dataViewType !== 'table' ? ' hidden' : '')}
                        >
                            <ErrorBoundary errorMsg={'Tabelle konnte nicht geladen werden'}>
                                <EDetailTable
                                    data={props.measurementSitesData?.Q?.measurements}
                                    predictionData={props.measurementSitesData?.Q?.predictions?.p50}
                                    waterLevel={false}
                                >
                                    <ErrorBoundary errorMsg={'Metadaten konnten nicht geladen werden'}>
                                        <MDetailMeasurementSitesMetaData
                                            msData={props.measurementSitesData?.Q}
                                            operator={props.operator}
                                            legendInfo={{
                                                legendText: props.msConfig?.legendText,
                                                legendURL: props.msConfig?.legendURL,
                                                forecastTime: props.measurementSitesData.Q.predictions?.time,
                                            }}
                                            isWaterLevel={false}
                                        />
                                    </ErrorBoundary>
                                </EDetailTable>
                            </ErrorBoundary>
                        </div>
                        <ErrorBoundary errorMsg={'Hochwasserereignisse konnten nicht geladen werden'}>
                            <MWaterEvents
                                extremeEvents={props.measurementSitesData?.extremeevents?.Q}
                                legends={props.legends?.Q}
                                threshold={props.legends?.thresholds[props.msNumber]?.Q}
                            />
                        </ErrorBoundary>
                    </TabPanel>
                )}

                <TabPanel
                    className="m-detail-measurementsites__tab-panel"
                    value={tabValue}
                    index={hasTab('W') && hasTab('Q') ? 2 : hasTab('W') || hasTab('Q') ? 1 : 0}
                >
                    <div className={'m-detail-measurementsites__table'}>
                        {props.msConfig && (
                            <TableContainer className={'m-detail-measurementsites__table-container'}>
                                <Table className={'m-detail-measurementsites__table-wrapper'}>
                                    <TableHead>
                                        <TableRow className="m-detail-measurementsites__table-row">
                                            <TableCell className="m-detail-measurementsites__table-left grey">
                                                Pegelkennwerte
                                            </TableCell>
                                            <TableCell className="m-detail-measurementsites__table-right grey"></TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        <TableRow className="m-detail-measurementsites__table-row">
                                            <TableCell className="m-detail-measurementsites__table-left">
                                                Betreiber
                                            </TableCell>
                                            <TableCell className="m-detail-measurementsites__table-right">
                                                {props.operator?.ms_url || props.operator?.op_url ? (
                                                    <a
                                                        href={props.operator?.ms_url || props.operator?.op_url}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {props.operator.op_name}
                                                    </a>
                                                ) : (
                                                    props.operator?.op_name
                                                )}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow className="m-detail-measurementsites__table-row">
                                            <TableCell className="m-detail-measurementsites__table-left">
                                                Einzugsgebiet
                                            </TableCell>
                                            <TableCell className="m-detail-measurementsites__table-right">
                                                {props.msConfig.catchmentArea
                                                    ? formatNumber(props.msConfig.catchmentArea) + ' km\u00B2'
                                                    : 'kein Wert vorhanden'}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow className="m-detail-measurementsites__table-row">
                                            <TableCell className="m-detail-measurementsites__table-left">
                                                Rechtswert (UTM32)
                                            </TableCell>
                                            <TableCell className="m-detail-measurementsites__table-right">
                                                {props.msConfig.easting
                                                    ? formatNumber(props.msConfig.easting, 1) + ' m'
                                                    : 'kein Wert vorhanden'}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow className="m-detail-measurementsites__table-row">
                                            <TableCell className="m-detail-measurementsites__table-left">
                                                Hochwert (UTM32)
                                            </TableCell>
                                            <TableCell className="m-detail-measurementsites__table-right">
                                                {props.msConfig.northing
                                                    ? formatNumber(props.msConfig.northing, 1) + ' m'
                                                    : 'kein Wert vorhanden'}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow className="m-detail-measurementsites__table-row">
                                            <TableCell className="m-detail-measurementsites__table-left">
                                                Pegelnullpunkt (DHHN2016)
                                            </TableCell>
                                            <TableCell className="m-detail-measurementsites__table-right">
                                                {props.msConfig.elevation
                                                    ? formatNumber(props.msConfig.elevation, 1) + ' m ü. NHN'
                                                    : 'kein Wert vorhanden'}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}

                        {type !== 'municipal' && (
                            <TableContainer className={'m-detail-measurementsites__table-container'}>
                                <Table className={'m-detail-measurementsites__table-wrapper'}>
                                    <TableHead>
                                        <TableRow className="m-detail-measurementsites__table-row">
                                            <TableCell className="m-detail-measurementsites__table-left grey">
                                                Wasserstandskennwerte
                                            </TableCell>
                                            <TableCell className="m-detail-measurementsites__table-right grey"></TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {props.legends?.thresholds && props.legends.thresholds[props.msNumber]?.W ? (
                                            legendWKeysSorted.map(
                                                (element, key) =>
                                                    props.legends.W[element] &&
                                                    props.legends.W[element].description !== '-' && (
                                                        <TableRow
                                                            key={key}
                                                            className="m-detail-measurementsites__table-row"
                                                        >
                                                            <TableCell className="m-detail-measurementsites__table-left">
                                                                {props.legends.W[element].description}
                                                            </TableCell>
                                                            <TableCell className="m-detail-measurementsites__table-right">
                                                                {
                                                                    // Prettier adding 2 spaces when using ternary operator.
                                                                    // @see https://github.com/prettier/prettier/issues/4979
                                                                    // prettier-ignore
                                                                    props.legends.thresholds[props.msNumber].W[element] >= 0
                                                                        ? (
                                                                            formatNumber(
                                                                                props.legends.thresholds[props.msNumber].W[element]
                                                                            ) +
                                                                            (props.msConfig?.isSeaSite ? ' m ü. NHN' : ' cm')
                                                                        )
                                                                        : 'kein Wert vorhanden'
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                            )
                                        ) : (
                                            <TableRow className="m-detail-measurementsites__table-row">
                                                <TableCell className="m-detail-measurementsites__table-left">
                                                    keine Werte verfügbar
                                                </TableCell>
                                                <TableCell className="m-detail-measurementsites__table-right"></TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>

                                {props.legends?.thresholds && props.legends.thresholds[props.msNumber]?.W && (
                                    <>
                                        <p className="m-detail-measurementsites__table-info-text">
                                            HWx steht für ein x-jährliches Hochwasser, d.h. ein Hochwasser, das im
                                            langjährigen statistischen Mittel alle x Jahre auftritt. Beispiel: Der
                                            2-jährliche Hochwasserstand (HW2) wird im statistischen Mittel einmal alle 2
                                            Jahre erreicht oder überschritten.
                                        </p>

                                        <p className="m-detail-measurementsites__table-info-text">
                                            Der HWx-Wert wird aus dem HQx-Wert mit der aktuell gültigen
                                            Wasserstand-Abfluss-Beziehung berechnet.
                                        </p>

                                        {(props.operator?.ms_url || props.operator?.op_url) && (
                                            <p className="m-detail-measurementsites__table-info-text">
                                                Weitere Informationen unter:{' '}
                                                <a
                                                    className="m-detail-measurementsites__table-info-text__link"
                                                    target={'_blank'}
                                                    href={props.operator?.ms_url || props.operator?.op_url}
                                                    rel="noreferrer"
                                                >
                                                    {props.operator?.ms_url || props.operator?.op_url}
                                                </a>
                                            </p>
                                        )}
                                    </>
                                )}
                            </TableContainer>
                        )}

                        {type !== 'municipal' && (
                            <TableContainer className={'m-detail-measurementsites__table-container'}>
                                <Table className={'m-detail-measurementsites__table-wrapper'}>
                                    <TableHead>
                                        <TableRow className="m-detail-measurementsites__table-row">
                                            <TableCell className="m-detail-measurementsites__table-left grey">
                                                Abflusskennwerte
                                            </TableCell>
                                            <TableCell className="m-detail-measurementsites__table-right grey"></TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {props.legends?.thresholds && props.legends.thresholds[props.msNumber]?.Q ? (
                                            legendQKeysSorted.map(
                                                (element, key) =>
                                                    props.legends.Q[element] &&
                                                    props.legends.Q[element].description !== '-' && (
                                                        <TableRow
                                                            key={key}
                                                            className="m-detail-measurementsites__table-row"
                                                        >
                                                            <TableCell className="m-detail-measurementsites__table-left">
                                                                {props.legends.Q[element].description}
                                                            </TableCell>
                                                            <TableCell className="m-detail-measurementsites__table-right">
                                                                {props.legends.thresholds[props.msNumber]?.Q[element] >=
                                                                0
                                                                    ? formatNumber(
                                                                          props.legends.thresholds[props.msNumber].Q[
                                                                              element
                                                                          ],
                                                                          1
                                                                      ) + ' m\u00B3/s'
                                                                    : 'kein Wert vorhanden'}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                            )
                                        ) : (
                                            <TableRow className="m-detail-measurementsites__table-row">
                                                <TableCell className="m-detail-measurementsites__table-left">
                                                    keine Werte verfügbar
                                                </TableCell>
                                                <TableCell className="m-detail-measurementsites__table-right"></TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>

                                {props.legends?.thresholds[props.msNumber]?.Q && (
                                    <>
                                        <p className="m-detail-measurementsites__table-info-text">
                                            HQx steht für ein x-jährliches Hochwasser, d.h. ein Hochwasser, das im
                                            langjährigen statistischen Mittel alle x Jahre auftritt. Beispiel: Der
                                            2-jährliche Hochwasserabfluss (HQ2) wird im statistischen Mittel einmal alle
                                            2 Jahre erreicht oder überschritten.
                                        </p>

                                        {(props.operator?.ms_url || props.operator?.op_url) && (
                                            <p className="m-detail-measurementsites__table-info-text">
                                                Weitere Informationen unter:{' '}
                                                <a
                                                    className="m-detail-measurementsites__table-info-text__link"
                                                    target={'_blank'}
                                                    href={props.operator?.ms_url || props.operator?.op_url}
                                                    rel="noreferrer"
                                                >
                                                    {props.operator?.ms_url || props.operator?.op_url}
                                                </a>
                                            </p>
                                        )}
                                    </>
                                )}
                            </TableContainer>
                        )}

                        {props.measurementSitesData.extremeevents?.W && type !== 'municipal' && (
                            <TableContainer className={'m-detail-measurementsites__table-container'}>
                                <Table className={'m-detail-measurementsites__table-wrapper'}>
                                    <TableHead>
                                        <TableRow className="m-detail-measurementsites__table-row">
                                            <TableCell className="m-detail-measurementsites__table-left grey">
                                                Historische Hochwasserereignisse (Wasserstand)
                                            </TableCell>
                                            <TableCell className="m-detail-measurementsites__table-right grey"></TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {props.measurementSitesData.extremeevents.W.length > 0 ? (
                                            props.measurementSitesData.extremeevents.W.map((element, key) => (
                                                <TableRow key={key} className="m-detail-measurementsites__table-row">
                                                    <TableCell className="m-detail-measurementsites__table-left">
                                                        {momentFormatDate(element.date)}
                                                    </TableCell>
                                                    <TableCell className="m-detail-measurementsites__table-right">
                                                        {formatNumber(element.value) + ' cm'}{' '}
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow className="m-detail-measurementsites__table-row">
                                                <TableCell className="m-detail-measurementsites__table-left">
                                                    keine Werte verfügbar
                                                </TableCell>
                                                <TableCell className="m-detail-measurementsites__table-right"></TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}

                        {props.measurementSitesData.extremeevents?.Q && type !== 'municipal' && (
                            <TableContainer className={'m-detail-measurementsites__table-container'}>
                                <Table className={'m-detail-measurementsites__table-wrapper'}>
                                    <TableHead>
                                        <TableRow className="m-detail-measurementsites__table-row">
                                            <TableCell className="m-detail-measurementsites__table-left grey">
                                                Historische Hochwasserereignisse (Abfluss)
                                            </TableCell>
                                            <TableCell className="m-detail-measurementsites__table-right grey"></TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {props.measurementSitesData.extremeevents.Q.length > 0 ? (
                                            props.measurementSitesData.extremeevents.Q.map((element, key) => (
                                                <TableRow key={key} className="m-detail-measurementsites__table-row">
                                                    <TableCell className="m-detail-measurementsites__table-left">
                                                        {momentFormatDate(element.date)}
                                                    </TableCell>
                                                    <TableCell className="m-detail-measurementsites__table-right">
                                                        {formatNumber(element.value, 1) + ' m\u00B3/s'}{' '}
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow className="m-detail-measurementsites__table-row">
                                                <TableCell className="m-detail-measurementsites__table-left">
                                                    keine Werte verfügbar
                                                </TableCell>
                                                <TableCell className="m-detail-measurementsites__table-right"></TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </div>
                </TabPanel>
            </div>
        )
    );
};

export default MDetailMeasurementSites;
