import { Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import { IMeasurementSiteConfig } from '../../../models/config';
import { IMeasurements } from '../../../models/misc';
import { formatNumber, momentFullFormat } from '../../../service/chartDataHandler';
import { checkTouchDevice } from '../../../utils/mapUtils';
import ErrorBoundary from '../error-boundary/EErrorBoundary';
import EPreviewChart from '../preview-chart/EPreviewChart';

interface IPreview {
    msConfig: IMeasurementSiteConfig;
    msData?: IMeasurements;
    riverAreaSlug: string | null;
    riverName: string | null;
    riverMunicipalName: string | null;
}

export type { IPreview as EMap__IMeasurementSitePreview };

const PreviewContent = (props: IPreviewContentProps) => {
    const predictionTime = props.msData?.predictions?.time;
    return (
        <>
            {props.msConfig.name && (
                <div className="e-map__tooltip__station-name">
                    <strong>
                        {props.msConfig.name}
                        {props.msConfig.type === 'municipal' && props.riverMunicipalName
                            ? ` / ${props.riverMunicipalName}`
                            : props.msConfig.type !== 'municipal' && props.riverName
                              ? ` / ${props.riverName}`
                              : ''}
                    </strong>
                </div>
            )}

            {props.msData && (
                <ErrorBoundary errorMsg={'Die Vorschau konnte nicht geladen werden'}>
                    <EPreviewChart
                        data={props.msData}
                        isSeaSite={props.msConfig?.isSeaSite}
                        label={props.msConfig.name}
                    />
                </ErrorBoundary>
            )}

            {props.msData && (
                <div className="e-map__tooltip__label-details">
                    <p className="e-map__tooltip__label-details__waterlevel">
                        <strong>Letzter Messwert: </strong>
                        {props.msData.xLast
                            ? momentFullFormat(props.msData.xLast) +
                              ' Uhr, ' +
                              (formatNumber(props.msData.yLast) + (props.msConfig.isSeaSite ? ' m ü. NHN' : ' cm'))
                            : 'keine Daten verfügbar'}
                    </p>

                    {predictionTime && (
                        <p className="e-map__tooltip__label-details__prediction">
                            <strong>Vorhersage vom: </strong>
                            {momentFullFormat(predictionTime)} Uhr
                        </p>
                    )}
                </div>
            )}

            {props.msData && props.showLink && (
                <div className="e-map__tooltip__link-wrapper">
                    <a
                        className="e-map__tooltip__link-wrapper__link"
                        href={'/flussgebiet/' + props.riverAreaSlug + '/' + props.msConfig?.slug}
                    >
                        Weiter zur Detailseite
                    </a>
                </div>
            )}
        </>
    );
};

/**
 * Component to render measurement site tooltip as overlay instead of leaflet tooltip.
 */
interface IPreviewProps extends IPreview {
    setMobilePreview: Dispatch<SetStateAction<IPreview>>;
}

const Preview = (props: IPreviewProps) => {
    const history = useHistory();
    const isTouchDevice = checkTouchDevice();

    return (
        <div className={'e-map__mobile-preview ' + (isTouchDevice ? 'e-map__mobile-preview--touch-device' : '')}>
            <div className="inner">
                <div className="head">
                    <div className="toggle-button" onClick={() => props.setMobilePreview(null)}>
                        <div className="toggle-button__inner"></div>
                    </div>
                </div>

                <div
                    className="e-map__tooltip"
                    onClick={() => history.push('/flussgebiet/' + props.riverAreaSlug + '/' + props.msConfig.slug)}
                >
                    <PreviewContent {...props} showLink={true} />
                </div>
            </div>
        </div>
    );
};

export { Preview as EMap__MeasurementSitePreview };

/**
 * Component to render tooltip and mobile preview content.
 */
interface IPreviewContentProps extends IPreview {
    showLink?: boolean;
}

export { PreviewContent as EMap__MeasurementSitePreviewContent };
