import './VPrecipitationIndex.scss';

const VPrecipitationIndex = () => {
    return (
        <div className="v-precipitation-index-map container">
            <h2>Standardisierter Niederschlagsindex Rheinland-Pfalz</h2>
            <div className="v-precipitation-index-map map-container" style={{}}>
                <img
                    src="/static/shared/map/niederschlagsindex_map.png"
                    alt="Niederschlagsindex Karte Rheinland-Pfalz"
                />
            </div>
        </div>
    );
};

export default VPrecipitationIndex;
