import { useMediaQuery } from '@mui/material';
import { DataGrid, GridColumns, deDE } from '@mui/x-data-grid';
import { useEffect, useMemo } from 'react';
import IConfig from '../../../models/config';
import { TWaterLevelTrends } from '../../../models/misc';
import { momentFullFormat } from '../../../service/chartDataHandler';
import '../../views/level-list/VLevelList.scss';
import './VWatertrend.scss';

interface IProps {
    config: IConfig;
    waterLevelTrends: TWaterLevelTrends;
    riverAreaId: number;
    riverAreaSlug: string;
}

interface IRowModel {
    number: number;
    name: string;
    riverName: string;
    timestamp: string;
    valueLastHour: string;
    valueLastHourBefore: string;
    trend: string;
    riverAreaSlug: string;
    slug: string;
}

const VWatertrend = ({ config, waterLevelTrends, riverAreaId, riverAreaSlug }: IProps) => {
    const isMobile = useMediaQuery('(max-width: 600px)');
    const columns: GridColumns<IRowModel> = useMemo(
        () => [
            {
                field: 'name',
                headerName: 'Pegel',
                flex: 1,
                minWidth: 85,
                sortComparator: (v1: string, v2: string) => v1.localeCompare(v2),
                renderCell: (params) => {
                    const row = params.row;
                    return row.riverAreaSlug && row.slug ? (
                        <a href={`/flussgebiet/${row.riverAreaSlug}/${row.slug}`}>{row.name}</a>
                    ) : (
                        row.name
                    );
                },
            },
            {
                field: 'riverName',
                headerName: 'Gewässer',
                flex: 1,
                minWidth: 90,
                sortComparator: (v1: string, v2: string) => v1.localeCompare(v2),
            },
            {
                field: 'timestamp',
                headerName: 'Zeitpunkt',
                flex: 1,
                minWidth: 160,
                sortComparator: (v1: string, v2: string) => v1.localeCompare(v2),
            },
            {
                field: 'valueLastHourBefore',
                headerName: 'Vorheriger Stundenwert',
                flex: 1,
                minWidth: isMobile ? 110 : 190,
                align: 'right',
                headerAlign: 'right',
                sortComparator: (v1: string, v2: string) => v1.localeCompare(v2),
                renderHeader: () => <div className="v-water-trend-custom-header">Vorheriger Stundenwert</div>,
            },
            {
                field: 'valueLastHour',
                headerName: 'Messwert',
                flex: 1,
                maxWidth: 100,
                minWidth: 85,
                align: 'right',
                headerAlign: 'right',
                sortComparator: (v1: string, v2: string) => v1.localeCompare(v2),
            },
            {
                field: 'trend',
                headerName: 'Differenz',
                flex: 1,
                maxWidth: 100,
                minWidth: 75,
                align: 'right',
                headerAlign: 'right',
                sortComparator: (v1: string, v2: string) => v1.localeCompare(v2),
            },
        ],
        [isMobile]
    );

    const rows = useMemo(() => {
        const sortedMeasurementSites: number[] = config.measurementsitesorted[riverAreaId];
        const result: IRowModel[] = [];

        sortedMeasurementSites.forEach((msNumber) => {
            const waterLevelTrend = waterLevelTrends[msNumber];
            if (waterLevelTrend) {
                const msConfig = config.measurementsite[msNumber];
                const riverNumber = msConfig.rivers[0];
                const unit = msConfig.isSeaSite ? ' m ü. NHN' : ' cm';
                const unitTrend = msConfig.isSeaSite ? ' m' : ' cm';
                result.push({
                    number: msNumber,
                    name: msConfig.name,
                    riverName: config.rivers[riverNumber]?.name,
                    timestamp: waterLevelTrend.time ? momentFullFormat(waterLevelTrend.time) + ' Uhr' : '-',
                    valueLastHour: waterLevelTrend.value ? waterLevelTrend.value + unit : '-',
                    valueLastHourBefore: waterLevelTrend.valueBefore ? waterLevelTrend.valueBefore + unit : '-',
                    trend:
                        waterLevelTrend.trend !== null
                            ? (waterLevelTrend.trend > 0 ? '+' : '') +
                              waterLevelTrend.trend.toLocaleString() +
                              unitTrend
                            : '-',
                    riverAreaSlug,
                    slug: msConfig.slug,
                });
            }
        });

        return result;
    }, [config, riverAreaId, riverAreaSlug, waterLevelTrends]);

    const handleScrollHorizontal = () => {
        const currentScrollPos = document.querySelector('.MuiDataGrid-virtualScroller').scrollLeft;
        const columnsHeaders = document.querySelectorAll(
            '.MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader:first-child)'
        );
        columnsHeaders.forEach((columnHeader) => {
            (columnHeader as HTMLElement).style.transform = `translate3d(-${currentScrollPos}px, 0px, 0px)`;
        });
    };

    useEffect(() => {
        const findVirtualScroller = () => {
            const virtualScrollerElement = document.querySelector('.MuiDataGrid-virtualScroller');
            if (!virtualScrollerElement) {
                setTimeout(findVirtualScroller, 100);
            } else {
                virtualScrollerElement.addEventListener('scroll', handleScrollHorizontal);
                return () => {
                    virtualScrollerElement.removeEventListener('scroll', handleScrollHorizontal);
                };
            }
            return 0;
        };
        findVirtualScroller();
    }, []);

    return (
        <div className="v-level-list v-water-trend">
            <DataGrid
                rowHeight={32}
                headerHeight={32}
                rows={rows}
                columns={columns}
                disableColumnMenu
                autoHeight
                disableSelectionOnClick
                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                hideFooterPagination={true}
                getRowId={(row) => row.number}
                sx={{
                    '.MuiDataGrid-virtualScroller': {
                        height: '260px !important',
                        overflowY: 'auto',
                    },
                    '& .MuiDataGrid-cell:first-of-type': {
                        position: 'sticky',
                        top: '0',
                        left: '0',
                        paddingLeft: '1.5rem',
                        zIndex: 999,
                        backgroundColor: '#FFFFFF',
                    },
                    '& .MuiDataGrid-columnHeader:first-of-type': {
                        position: 'sticky',
                        top: '0',
                        left: '0',
                        paddingLeft: '1.5rem',
                        border: 'none',
                        zIndex: 999,
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        '& .MuiDataGrid-columnHeadersInner': {
                            transform: 'none !important',
                            '& div': {
                                '& .MuiDataGrid-columnHeader:first-of-type': {
                                    backgroundColor: '#FFFFFF',
                                    zIndex: 2,
                                },
                            },
                        },
                    },
                }}
                columnBuffer={columns.length}
            />
        </div>
    );
};

export default VWatertrend;
